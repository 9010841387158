@import "../../styles/main.module.scss";

$border-color: #cccccc;
$active-color: #f2aa3f;

.tab {
  @extend .col-md-auto;
  border-left: 1px solid #cccccc;
  padding: calc($spacer/2) ($spacer * 3) calc($spacer/2) ($spacer);
  background-color: $gray-200;
  cursor: pointer;
  position: relative;

  &.active {
    background: $white;
    border-top: $active-color 2px solid;

    & > div:first-of-type {
      @extend .fw-bold;
    }
  }

  svg {
    right: 12px;
  }
}
