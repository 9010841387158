@import "../../styles/main.module.scss";

.spinner {
  display: inline-block;
  width: 33px;
  height: 33px;
}
.spinner:after {
  content: " ";
  display: block;
  width: 28px;
  height: 28px;
  margin: 4px;
  border-radius: 50%;
  border: 4px solid $primary;
  border-color: $primary transparent $primary transparent;
  animation: spinner 1.2s linear infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
