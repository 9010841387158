@import "../../styles/main.module.scss";

.react-calendar {
  position: absolute;
  width: 287px;
  border: solid 1px $dark;
  border-radius: 4px;
  box-shadow: 1px 1px 3px $gray-200;
  max-width: none;
  margin-top: 0.5em;
  z-index: 100;
}

.react-calendar__month-view__weekdays {
  background: $light;
  text-transform: capitalize;
  padding: 0 18px;

  * {
    font-size: 12px;
    text-decoration: none !important;
    padding: 10px 0;
  }
}

.react-calendar__month-view__days {
  padding: 0 18px 6px 18px;
}

.react-calendar__month-view__days__day {
  font-size: 11px;
  padding: 7px 5px;
  color: $body-color;
}

.react-calendar__month-view__days__day--weekend {
  color: $body-color;
}

.react-calendar__tile--active {
  background: $primary;
  color: white;
}

.react-calendar__tile--now {
  background: none;
  font-weight: bold;
}

.react-calendar__navigation {
  margin: 0;
  border-bottom: 1px solid $dark;
}

.react-calendar__navigation__label__labelText {
  font-size: 13px;
  font-weight: bold;
  text-transform: capitalize;
}

.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__year-view__months__month {
  text-transform: capitalize;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 1.25em 0;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: $primary;
}

.react-calendar__tile--now:enabled:hover {
  background: #e6e6e6;
  color: $body-color;
}

.react-calendar__tile--now.react-calendar__tile--active {
  background: $primary;
}

.form-control.form-control-sm.calendar-input {
  padding-left: 2.5em;
  font-size: 13px;
}
