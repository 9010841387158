.modalBackdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  pointer-events: all;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContainer {
  width: 446px;
  padding: 24px;
  background: white;
  border-radius: 4px;
  border: solid 1px #979797;
}

.formModal {
  width: 60%;
  background: white;
  border-radius: 4px;
  border: solid 1px #979797;
}

.moreInfoModal {
  width: 762px;
  background: white;
  border-radius: 4px;
  border: solid 1px #979797;
  padding: 1% 2%;
}
