.toast {
  width: 400px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  opacity: 1;
  padding: 16px;
  color: #424242;
  cursor: pointer;
  border: 1px solid #90cb7e;
  border-radius: 6px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

  transition: 0.2s;
  animation: fadeIn 0.75s;
  margin-top: 10%;
}
.toast:hover {
  transition: 0.2s;
  transform: scale(0.95);
  box-shadow: 0px 0px 3px gray;
}
.success {
  background-color: #eaf6e5;
  border-color: #90cb7e;
}
.warning {
  background-color: #f6f4e5;
  border-color: #e9dd70;
}
.error {
  background-color: #f6e5e5;
  border-color: #ee5252;
}
.info {
  background-color: #e5eef6;
  border-color: #419eeb;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
