.container {
  position: relative;
  display: inline;
  &:hover .tootip {
    display: block;
  }
}

.tootip {
  display: none;
  position: absolute;
  border: 1px solid rgb(226, 221, 122);
  padding: 10px;
  width: 230px;
  font-size: 11px;
  line-height: 1.4;
  background: rgb(247, 245, 223);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  bottom: 35px;
  transform: translateX(-50%);
  left: 50%;
  z-index: 9;

  &:after {
    content: "";
    background: #f7f5df;
    width: 15px;
    height: 15px;
    position: absolute;
    bottom: -8px;
    border-right: 1px solid #e2dd7a;
    border-bottom: 1px solid #e2dd7a;
    transform: rotate(45deg);
    left: 50%;
    margin-left: -7px;
    box-shadow: 1px 1px 3px -2px rgba(0, 0, 0, 0.3);
  }
}
