.radio {
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 0.5em;
  font-size: 13px;
  color: var(--color);
}

.radio__label {
  line-height: 16px;
  transition: 180ms all ease-in-out;
  opacity: 0.8;
}

.radio__input {
  display: flex;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.radio_before {
  .radio__control {
    display: grid;
    place-items: center;
  }

  input + .radio__control::before {
    content: "";
    width: 8px;
    height: 8px;
    box-shadow: inset 0.5em 0.5em #6656c1;
    border-radius: 50%;
    transition: 180ms transform ease-in-out;
    transform: scale(0);
  }

  input:checked + .radio__control::before {
    transform: scale(1);
  }
}

.radio__control {
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #6656c1;
  transform: translateY(-0.05em);
}
